import { useState } from "react";
import HistoryEntry from "./HistoryEntry";
import FullEntry from "./FullEntry";
import { Typography } from "@mui/material";
import { l } from "../../Lang";
import BackButton from "../common/BackButton";
import "./HistoryPage.scss";

export type Story = {
  ENTRY: string;
  STATUS: string;
  DATE: string;
  TITLE_EN: string;
  TITLE_ES: string;
  TITLE_PT: string;
  DESCRIPTION_EN: string;
  DESCRIPTION_ES: string;
  DESCRIPTION_PT: string;
  FEATURES: string[];
  CHANGES: {
    DATE_CHANGE: string;
    DESCRIPTION_CHANGE_EN: string;
    DESCRIPTION_CHANGE_ES: string;
    DESCRIPTION_CHANGE_PT: string;
  }[];
};

type HistoryPageProps = {
  entries: Story[];
  onBack: () => void;
};

const HistoryPage = ({ entries, onBack }: HistoryPageProps) => {
  const [storySelected, setStorySelected] = useState<string>("");

  const handleBack = () => {
    if (!!storySelected.length) setStorySelected("");
    else onBack();
  };

  return (
    <div className="history_page">
      {!!storySelected.length ? (
        <>
          <BackButton onBack={handleBack} />
          <FullEntry
            story={entries.find((entry) => entry.ENTRY === storySelected)}
          />
        </>
      ) : (
        <div className="history_content">
          <div className="history_header">
            <Typography variant="h1">{l("history_title")}</Typography>
            <Typography variant="body1">{l("history_subtitle")}</Typography>
          </div>
          <div className="all_entries">
            {!!entries ? (
              entries
                  .sort((a, b) => a.DATE < b.DATE ? 1 : -1)
                  .map((his: Story, index) => (
                <HistoryEntry
                  key={index}
                  entry={his}
                  onOpen={(entryId) => setStorySelected(entryId)}
                />
              ))
            ) : (
              <Typography variant="h4">{l("history_no_entries")}</Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryPage;
