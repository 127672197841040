import { Chip, Icon } from "@mui/material";
import { l } from "../../Lang";
import "./StatusChip.scss";

const Error: Record<number, { class: string; text: string; icon: any }> = {
  0: {
    class: "ok",
    text: "chart_error_state_ok",
    icon: <Icon>check_circle</Icon>,
  },
  1: {
    class: "warning",
    text: "chart_error_state_warning",
    icon: <Icon>error_outline</Icon>,
  },
  2: {
    class: "error",
    text: "chart_error_state_error",
    icon: <Icon>remove_circle_outline</Icon>,
  },
};

const StatusChip = ({ status }: { status: number }) => {
  return (
    <Chip
      size="small"
      className={Error[status].class}
      icon={Error[status].icon}
      label={l(Error[status].text)?.toUpperCase()}
    />
  );
};
export default StatusChip;
