import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import {MuiThemeV5} from "./components/context/MuiThemeV5";

import App from "./App";

import "./app.css";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

if (window.location.href && window.location.href.indexOf('status.botmaker.app') > -1)
    window.location.href = 'https://status.botmaker.com/';

root.render(
    <React.StrictMode>
        <MuiThemeV5>
            <CssBaseline/>
            <App/>
        </MuiThemeV5>
    </React.StrictMode>
);
