import { useState } from "react";
import {
  IconButton,
  Icon,
  Tabs,
  Tab,
  Box,
  Avatar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import "./Header.scss";
import { l } from "../../Lang";
import LangSelector from "../common/LangSelector";
import { changeMomentLocale } from "../../Utils";

type HeaderProps = {
  pageTitle: string;
  businessName: string;
  onPageChange: (newPage: string) => string;
  languageSelected: string;
  profileLogged?: {
    name?: string;
    picture?: string;
  };
  setLanguageSelected: (languageSelected: string) => void;
  showHistory?: boolean;
  showLogs?: boolean;
};

const Header = ({
  pageTitle,
  businessName,
  onPageChange,
  profileLogged,
  languageSelected,
  setLanguageSelected,
  showHistory = false,
  showLogs = false,
}: HeaderProps) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>("MAIN");

  const chandleChangePage = (newPage: string) => {
    setTabValue(newPage);
    onPageChange(newPage);
    setDrawerOpen(false);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleLanguageClose = (languageSelected: string) => {
    localStorage.setItem("language", languageSelected);
    changeMomentLocale();
    setLanguageSelected(languageSelected);
  };

  return (
    <>
      <header className="header">
        <div className="left-section">
          <img
            src="https://storage.googleapis.com/botmaker/website/logo-430x91px.svg"
            alt="Botmaker"
          />
        </div>
        <div className="mid-section not-mobile">
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => chandleChangePage(newValue)}
          >
            <Tab label={l("header_main")} value="MAIN" />
            {showHistory && (
              <Tab label={l("header_see_history")} value="HISTORY" />
            )}
            {showLogs && <Tab label={l("header_change_logs")} value="LOGS" />}
          </Tabs>
        </div>

        <div className="right-section">
          <div className="title">{businessName}</div>
          <div className="division not-mobile"></div>
          <div className="page-profile">
            <div className="not-mobile">
              <LangSelector
                lang={languageSelected}
                change={(country) => {
                  handleLanguageClose(country);
                }}
              />
            </div>
            <Avatar className="not-mobile">
              <img
                src={profileLogged?.picture || undefined}
                alt={profileLogged?.name || undefined}
              />
            </Avatar>
            <IconButton
              className="menu-icon mobile"
              color="primary"
              onClick={toggleDrawer(true)}
            >
              <Icon>menu</Icon>
            </IconButton>
          </div>
        </div>
      </header>

      <SwipeableDrawer
        className="mobile mobile-drawer"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        anchor="right"
      >
        <Box>
          <List>
            <ListItem className="first-item">
              <ListItemText
                primary={
                  <Typography variant="h3">
                    {l("header_mobile_menu")}
                  </Typography>
                }
              />
              <IconButton onClick={toggleDrawer(false)}>
                <Icon>close</Icon>
              </IconButton>
            </ListItem>
            <ListItem className="list-item-user">
              <ListItemAvatar>
                <Avatar>
                  <img
                    src={profileLogged?.picture || undefined}
                    alt={profileLogged?.name || undefined}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body1">
                    {profileLogged?.name || ""}
                  </Typography>
                }
                secondary={
                  <Typography variant="body2">{businessName}</Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h4">{l("header_main")}</Typography>
                }
                onClick={() => chandleChangePage("MAIN")}
              />
            </ListItem>
            {showHistory && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h4">
                      {l("header_see_history")}
                    </Typography>
                  }
                  onClick={() => chandleChangePage("HISTORY")}
                />
              </ListItem>
            )}
            {showLogs && (
              <ListItem>
                <ListItemText
                  primary={
                    <Typography variant="h4">
                      {l("header_change_logs")}
                    </Typography>
                  }
                  onClick={() => chandleChangePage("LOGS")}
                />
              </ListItem>
            )}
            <ListItem>
              <LangSelector
                lang={languageSelected}
                change={(country) => {
                  handleLanguageClose(country);
                }}
              />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default Header;
