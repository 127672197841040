import LogEntry from "./LogEntry";
import { Typography } from "@mui/material";
import { l } from "../../Lang";
import "./LogsPage.scss";

export type Log = {
  ENTRY: string;
  DATE: string;
  VERSION_EN: string;
  VERSION_ES: string;
  VERSION_PT: string;
  CHANGES: {
    CHANGES_EN: string;
    CHANGES_ES: string;
    CHANGES_PT: string;
  }[];
};

type LogsPageProps = {
  logs: Log[];
  onBack: () => void;
};

const LogsPage = ({ logs, onBack }: LogsPageProps) => {

  return (
    <div className="logs_page">
      <div className="logs_header">
        <Typography variant="h1">{l("header_change_logs")}</Typography>
        <Typography variant="body1">{l("logs_subtitle")}</Typography>
      </div>
      <div className="all_logs">
        {!!logs ? (
          logs
              .sort((a, b) => a.DATE < b.DATE ? 1 : -1)
              .map((log: Log, index) => <LogEntry key={index} entry={log} />)
        ) : (
          <Typography variant="h4">{l("history_no_entries")}</Typography>
        )}
      </div>
    </div>
  );
};

export default LogsPage;
