import { Button, Typography, Icon } from "@mui/material";
import { getLang, l } from "../../Lang";
import "./ContentHeader.scss";

const Status: Record<number, { text: string; subtatus?: string; icon: any }> = {
  0: {
    text: "status_header_ok_title",
    icon: <Icon className="ok">check_circle</Icon>,
  },
  1: {
    text: "status_header_warning_title",
    subtatus: "status_header_warning_substatus",
    icon: <Icon className="warning">error_outline</Icon>,
  },
  2: {
    text: "status_header_error_title",
    subtatus: "status_header_error_substatus",
    icon: <Icon className="error">remove_circle_outline</Icon>,
  },
};

type ContentHeaderProps = {
  status: number | undefined;
  subStatus?: string[];
  lastUpdate: string | undefined;
};

const ContentHeader = ({
  status,
  subStatus,
  lastUpdate,
}: ContentHeaderProps) => {
  const createSubStatus = (status: number, subStatus: string[]) => {
    if (status === 0 || !subStatus.length) return null;
    if (subStatus.length === 1)
      return `${l(Status[status].subtatus)} ${subStatus[0]}.`;
    const lastIndex = subStatus.length - 1;
    return `${l(Status[status].subtatus)} ${subStatus
      .slice(0, lastIndex)
      .join(", ")} ${l("and")} ${subStatus[lastIndex]}.`;
  };

  const isLoading = status === undefined || !lastUpdate;

  return (
    <div className="status-header">
      <div className="status-info">
        <div className="status-title">
          {isLoading ? (
            <Typography variant="h1">{l("loading")}</Typography>
          ) : (
            <>
              {Status[status].icon}
              <Typography variant="h1">{l(Status[status].text)}</Typography>
              {subStatus && (
                <Typography variant="subtitle1" className="sub-title">
                  {createSubStatus(status, subStatus)}
                </Typography>
              )}
            </>
          )}
        </div>
        {!isLoading && (
          <Typography variant="subtitle2" className="date">
            {l("status_header_last_update")} {lastUpdate}
          </Typography>
        )}
      </div>
      <div className="status-buttons">
        <Button
          variant="text"
          onClick={(_) => {
            window.open(
              `https://help.botmaker.com/${getLang()}/support`,
              "_blank"
            );
          }}
        >
          {l("status_header_brn_report")}
        </Button>
      </div>
    </div>
  );
};

export default ContentHeader;
