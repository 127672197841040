import { Log } from "./LogsPage";
import { Typography } from "@mui/material";
import { getLang } from "../../Lang";
import { formatDateDDMMYYYY } from "../../Utils";

type LogEntryProps = {
  entry: Log;
};

const LogEntry = ({ entry }: LogEntryProps) => {
  const lang = getLang().toUpperCase();

  const getFieldByLang = (
    field: "VERSION" | "CHANGES",
    changes?: {
      CHANGES_EN: string;
      CHANGES_ES: string;
      CHANGES_PT: string;
    }
  ): string => {
    if (changes) {
      const key = `${field}_${lang}` as keyof typeof changes;
      return changes[key] as string;
    } else {
      const key = `${field}_${lang}` as keyof Log;
      return entry[key] as string;
    }
  };

  return (
    <div className="log_entry">
      <div className="log_header">
        <Typography variant="h6">{getFieldByLang("VERSION")}</Typography>
        <Typography variant="body2">
          {formatDateDDMMYYYY(entry.DATE)}
        </Typography>
      </div>
      <ul>
        {entry.CHANGES.map((change, index) => (
          <li key={index} className="change">
            <Typography variant="body1">
              {getFieldByLang("CHANGES", change)}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default LogEntry;
