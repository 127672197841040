import { l } from "../../Lang";
import { Typography, Icon } from "@mui/material";
import "./StatusLegend.scss";

const Errors: Record<number, { text: string; icon: any }> = {
  0: {
    text: "chart_error_state_ok",
    icon: <Icon className="ok">check_circle</Icon>,
  },
  1: {
    text: "chart_error_state_warning",
    icon: <Icon className="warning">error_outline</Icon>,
  },
  2: {
    text: "chart_error_state_error",
    icon: <Icon className="error">remove_circle_outline</Icon>,
  },
};

const StatusLegend = ({ status }: { status?: number | undefined }) => {
  if (status !== undefined) {
    return (
      <div className="status-legend">
        {Errors[status] && (
          <div className="item">
            {Errors[status].icon}
            <Typography variant="body2">{l(Errors[status].text)}</Typography>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="status-legend">
      {Object.entries(Errors).map(([key, { text, icon }]) => (
        <div key={key} className="item">
          {icon}
          <Typography variant="body2">{l(text)}</Typography>
        </div>
      ))}
    </div>
  );
};

export default StatusLegend;
