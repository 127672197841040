import { Story } from "./HistoryPage";
import StatusChip from "../common/StatusChip";
import { Button, Typography, Icon } from "@mui/material";
import { l, getLang } from "../../Lang";
import { getStatusByName, formatDateHistoryTitle } from "../../Utils";

type HistoryEntryProps = {
  entry: Story;
  onOpen: (entryId: string) => void;
};

const HistoryEntry = ({ entry, onOpen }: HistoryEntryProps) => {
  const lang = getLang().toUpperCase();

  const getFieldByLang = (
    field: "TITLE" | "DESCRIPTION" | "DESCRIPTION_CHANGE"
  ): string => {
    const key = `${field}_${lang}` as keyof Story;
    return entry[key] as string;
  };

  return (
    <div className="history_entry">
      <div>
        <StatusChip status={getStatusByName(entry.STATUS)} />
      </div>

      <div className="entry_content">
        <Typography variant="h4">
          {formatDateHistoryTitle(entry.DATE)}
        </Typography>
        <div className="entry_text">
          <Typography variant="h6">{getFieldByLang("TITLE")}</Typography>
          <div>
            <Typography variant="body1">
              {l("history_summary_issue")}
            </Typography>
            <Typography variant="body2">
              {getFieldByLang("DESCRIPTION")}
            </Typography>
          </div>
        </div>
      </div>
      <div className="history_btn_see_more">
        <Button
          variant="text"
          startIcon={<Icon>add</Icon>}
          onClick={() => onOpen(entry.ENTRY)}
        >
          {l("header_read_incident")}
        </Button>
      </div>
    </div>
  );
};
export default HistoryEntry;
