import { useEffect } from "react";
import { Story } from "./HistoryPage";
import StatusChip from "../common/StatusChip";
import { Typography } from "@mui/material";
import { l, getLang } from "../../Lang";
import {
  getStatusByName,
  formatDateHistoryTitle,
  formatDateHistoryeEntry,
} from "../../Utils";

type FullEntryProps = {
  story: Story | undefined;
};

const FullEntry = ({ story }: FullEntryProps) => {
  const lang = getLang().toUpperCase();

  useEffect(() => {
    const scrollableElement = document.getElementsByClassName("scroll-box")[0];
    if (scrollableElement) {
      scrollableElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, []);

  if (!story) return null;

  const getFieldByLang = (
    field: "TITLE" | "DESCRIPTION" | "DESCRIPTION_CHANGE",
    incident?: {
      DATE_CHANGE: string;
      DESCRIPTION_CHANGE_EN: string;
      DESCRIPTION_CHANGE_ES: string;
      DESCRIPTION_CHANGE_PT: string;
    }
  ): string => {
    if (incident) {
      const key = `${field}_${lang}` as keyof typeof incident;
      return incident[key] as string;
    } else {
      const key = `${field}_${lang}` as keyof Story;
      return story[key] as string;
    }
  };

  const getEntryRight = (isMobile: boolean) => {
    return (
      <div className={`entry-right ${isMobile ? "mobile" : "not-mobile"}`}>
        <Typography variant="h6">{l("header_affected_features")}</Typography>
        {story.FEATURES.map((system, index) => (
          <Typography key={index} variant="body2">
            {system}
          </Typography>
        ))}
      </div>
    );
  };

  return (
    <div className="full_story">
      <div>
        <StatusChip status={getStatusByName(story.STATUS)} />
      </div>
      <div className="entry_content">
        <Typography variant="h4">
          {formatDateHistoryTitle(story.DATE)}
        </Typography>
        <div className="entry_table">
          {getEntryRight(true)}
          <div className="entry_text">
            <Typography variant="h6">{getFieldByLang("TITLE")}</Typography>
            <div>
              <Typography variant="body1">{"Resumen del problema:"}</Typography>
              <Typography variant="body2">
                {getFieldByLang("DESCRIPTION")}
              </Typography>
            </div>
            {story.CHANGES.map((incident, index) => (
              <div key={index}>
                <Typography variant="body2">
                  {getFieldByLang("DESCRIPTION_CHANGE", incident)}
                </Typography>
                <Typography variant="caption">
                  {formatDateHistoryeEntry(incident.DATE_CHANGE)}
                </Typography>
              </div>
            ))}
          </div>
          {getEntryRight(false)}
        </div>
      </div>
    </div>
  );
};
export default FullEntry;
